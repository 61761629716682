import React, { useEffect, useState } from 'react';
import "./aboutteam.scss"



const TeamDetails = () => {
    const images = [
        `${process.env.PUBLIC_URL}/test_3.svg`,
        `${process.env.PUBLIC_URL}/test_3.svg`,   
          `${process.env.PUBLIC_URL}/test_3.svg`,  
           `${process.env.PUBLIC_URL}/test_3.svg`, 
               `${process.env.PUBLIC_URL}/test_3.svg`,
      ];
    const [gridImages, setGridImages] = useState(images);

    useEffect(() => {
      const interval = setInterval(() => {
        setGridImages((prevImages) => {
          const [first, ...rest] = prevImages;
          return [...rest, first]; // Rotate the images every 3 seconds
        });
      }, 111000);
  
      return () => clearInterval(interval); // Clear interval on unmount
    }, []);
    
  return (
    <div className="teamgroupParent">
               


                  <div className="teammeetOurParent">

                  <div className="teammeetOur">Meet our</div>
                  <div className="teamamazingTeamTxtContainer">
                  <span style={{color : "black"}}>Amazing</span>
                  <span className="teamteam"> team</span>
                  </div>
                  <div className="teamdiscoverTheBrilliance1">Discover the brilliance behind  embrace. Our team blends innovation and artistry to craft digital wonders that captivate</div>

                  </div>

                

                  <div className="teammember4Parent">

                     <div className="teammember1">

                                        <img className="teamuserpicIcon" alt=""  src="/review5.jpg" />

                                   
                                        <div className="teammembername">Athar</div>
                                        <div className="teamrole">Co-founder & ceo</div>

                                        <div className="socialinfoteam">

                                        <div className="teamfaX" > 
                                        <img  className = "social_team" alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 

                                        <div className="teamfalinkdin">
                                        <a href="https://www.linkedin.com/in/connectwithathar/" target="_blank" rel="noopener noreferrer">
                                        <img className = "social_team" alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </a>
                                        </div>

                                        <div className="teamfaemail">
                                        <img className = "social_team" alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                       
                                        </div>


                     </div> 
                        
                        <div className="teammember1">
                                        <img className="teamuserpicIcon" alt=""  src="/review9.png" />
                               
                                        <div className="teammembername">Anil </div>
                                        <div className="teamrole">Co-founder & coo </div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img className = "social_team" alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div>

                                        <div className="teamfalinkdin">
                                        <a href="https://www.linkedin.com/in/aniljunagal" target="_blank" rel="noopener noreferrer">
                                        <img className = "social_team" alt="" src="linkedin.png" height="20px" width="20px" />
                                        </a>
                                        </div>

                                        <div className="teamfaemail">
                                        <img className = "social_team" alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>

                                        </div>
                                     
                                     
                        </div> 
                        
                        <div className="teammember1">
                                        <img className="teamuserpicIcon" alt=""  src="/review2.png" />
                                  
                                  
                                        <div className="teammembername">Jatin</div>
                                        <div className="teamrole"> Lead engineer</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img className = "social_team"  alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <img  className = "social_team" alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </div>
                                        <div className="teamfaemail">
                                        <img className = "social_team"  alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                        </div>
                        
                         <div className="teammember1">
                                        <img className="teamuserpicIcon" alt="" src="/review10.jpg" />
                                
                                
                                        <div className="teammembername">Archit</div>
                                        <div className="teamrole">Sde intern</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img className = "social_team" alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <a href="https://www.linkedin.com/in/archityadav24092002/" target="_blank" rel="noopener noreferrer">
                                        <img className = "social_team"  alt="" src="linkedin.png" height="20px" width="20px" />
                                        </a>
                                       </div>
                                        <div className="teamfaemail">
                                        <img className = "social_team" alt= "" src ="mail.png"  height="20px" width="20px"/>


                                        </div>
                                        </div>
                        </div>




                         <div  className="teammember1">
                                        <img className="teamuserpicIcon" alt="" src="/review7.jpg" />
                                  
                                  
                                        <div className="teammembername">Hannah</div>
                                        <div className="teamrole">Commercial advisor</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img className = "social_team" alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <img className = "social_team" alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </div>
                                        <div className="teamfaemail">
                                        <img className = "social_team" alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                
                                
                        </div>
                        </div>
                        
                         <div className="teammember1">
                                        <img className="teamuserpicIcon" alt="" src="/review8.png" />
                                      
                                        <div className="teammembername">Liam</div>
                                        <div className="teamrole">Technical advisor</div>
                                        <div className="socialinfoteam">
                                        <div className="teamfaX" > 
                                        <img className = "social_team" alt = "" src = "twitter.png" height="20px" width="20px"/>
                                        </div> 
                                        <div className="teamfalinkdin">
                                        <img className = "social_team" alt ="" src ="linkedin.png" height="20px" width="20px" />
                                        </div>
                                        <div className="teamfaemail">
                                        <img className = "social_team" alt= "" src ="mail.png"  height="20px" width="20px"/>
                                        </div>
                                        </div>
                                        </div>
           

                  
                  </div>

                  </div>);
 }
 


export default TeamDetails
