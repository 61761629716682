import React, { useState, useEffect } from 'react';
import { CREATE_USER_MUTATION } from "./createUserMutation";
import { SIGN_IN_USER_MUTATION } from "./signInUserMutation" ;
import { CREATE_USER_ECOMM } from './ecomcreate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate ,Link } from 'react-router-dom';
import "./page.scss";



  const AuthPage = ({ setAuthenticated, setToken }) => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
 // console.log('GraphQL Endpoint:', graphqlEndpoint);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [lastName , setlastName] =useState('')
  const [firstName , setfirstName] =useState('') ;
  const [role , setrole] =  useState('') ;
  const navigate = useNavigate();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
// to get inside its necc
  useEffect(() => {
    const storedToken = localStorage.getItem('authToken');
   // const ecomUser_id = sessionStorage.getItem('ecomUser_id')
    if (storedToken) {
      setAuthenticated(true);
      navigate('/Listyourshelf' );
    }
    }, [setAuthenticated, navigate]);

// get role of come form signup page 
  useEffect(() => {
    sessionStorage.setItem('role', role);
  }, [role]);



  // Update role state when input changes
  const handleRoleChange = (e) => {
    setrole(e.target.value);
  };


// all input mandatory
  const handleBothClicks = async () => {
    try {
  
      if (!email || !firstName || !lastName || !password || !isValidPassword(password) || !isCheckboxChecked || !role) {
        toast.error('Please fill in all fields correctly.');
        return;
      }

        const variables = {
        userEmail: email,
        userPassword: password,
        lastName: lastName,  // Assuming you have `lastName` variable
        firstName: firstName  ,// Assuming you have `firstName` variable
        role :  role ,
         };
      
    
       const check_role = sessionStorage.getItem("role");
       let mutation;
       if (check_role === "ADMIN") {
         mutation = CREATE_USER_MUTATION;
       } else if (check_role === "ECOMM") {
         mutation = CREATE_USER_ECOMM;
       }   
const response = await fetch(graphqlEndpoint, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  body: JSON.stringify({
    query:mutation, // Use the selected mutation
    variables,
  }),
});


const data = await response.json();

//check_role = localStorage.getItem('role');
let responsefromlogin = null;

if (check_role === 'ADMIN') {
  responsefromlogin = data.data.createUser;
  toast.success('Account created successfully!');

} else if (check_role === 'ECOMM') {
  responsefromlogin = data.data.createEcommUser;
  toast.success('Account created successfully!');
}

if (responsefromlogin ) {
  toast.success('Account created successfully!');

}
} catch (error) {
console.error('Sign up error:', error);
toast.error('Sign up error. Please try again.');
}
};

   
//sign in 

  const handleSignIn = async () => {
    try {
      if (!email) {
        toast.error('Please enter your email.');
        return;
      }
      if (!password) {
        toast.error('Please enter your password.');
        return;
      }

        
      const variables = { userEmail: email, userPassword: password  };

      const response = await fetch(graphqlEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query: SIGN_IN_USER_MUTATION,
          variables,
        }),
      });

                 const data = await response.json();
                 if (data.data.signInUser) {
                 // console.log("data is " ,data.data.signInUser )
                 setAuthenticated(true);
               
             //     console.log(data)
                // sessionStorage.setItem('ecomUser_id' , ecomUser_id) ;
                 const token = data.data.signInUser.token;
                 

                 if (data.data && data.data.signInUser && data.data.signInUser.users ) {
                 const registerStores = data.data.signInUser.users.registerStores;
                  const role = data.data.signInUser.users.UserRole ;
                 localStorage.setItem('register_store' , registerStores);
                 localStorage.setItem('role' ,role )
                 
                 for (const store of registerStores) {
                 const storeId = store.storeId;
             
                 }
                 } else {
                 console.error('Invalid response structure:', data);
                 }

                 if (data.data.signInUser.ecommUser !== null) {
                  const ecomUser_id = data.data.signInUser.ecommUser.ecomUser_id;
                  const FirstName = data.data.signInUser.ecommUser.FirstName ;
                  const lastName = data.data.signInUser.ecommUser.lastName ;
                  const  EcomUserEmail = data.data.signInUser.ecommUser.EcomUserEmail ;
                  const role = data.data.signInUser.ecommUser.EcomUserRole ;
                  localStorage.setItem('role',role) ;
                  sessionStorage.setItem('ecomUser_id', ecomUser_id);
                  sessionStorage.setItem('EcomUserEmail',EcomUserEmail) ;
                  sessionStorage.setItem('user_name1', FirstName ) ;
                  sessionStorage.setItem('user_name2', lastName );
                }
                
                 
      const FirstName = data.data.signInUser.users.FirstName ;
      const lastName = data.data.signInUser.users.lastName ;
 
    
     
        localStorage.setItem('user_email', email);
        localStorage.setItem('authToken', token);
        localStorage.setItem('user_name1', FirstName ) ;
        localStorage.setItem('user_name2', lastName );

        setToken(token);
     window.location.reload();
     navigate(`/AuthPage`);
   
       // navigate to  home after sigin 
        
      }  else {
        toast.error('Invalid email or password. Please try again.');
      }
    } catch (error) {
      console.error('Sign in error:', error);
    }
  };
 

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,}$/;
    return passwordRegex.test(password);
  };


  const isValidLength = password.length >= 7;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*]/.test(password);

  const allValid = isValidLength && hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
  
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };


  const [activePage, setActivePage] = useState('login');

  const handleButtonClick = (page) => {
    setActivePage(page);
    };
    const CombinedClickHandler = async () => {
      try {
        await handleBothClicks(); // Call the sign-up function
      //  window.location.reload();
        // Introduce a 2-second delay before calling the sign-in function
        setTimeout(async () => {
          await handleSignIn(); // Call the sign-in function
        }, 1000);
  
         // await navigate('/Listyourshelf');
  
      } catch (error) {
        console.error('Combined click error:', error);
      }
    };
  
    const handleSignInButtonClick = async () => {
      // Call handleSignIn function
      await handleSignIn();
    };
  
  return (
    <div className='login_mainpage '>

      

                     <div className="logsidePage1">
                   
                <img className="login_sidebar" alt="" src="/login_sidebar.svg" />


                     </div>


                     <div className="loggroupParent">
                      <div className="logframeWrapper">



                      <div className='auth_logo_input_field'>

                                  <Link to="/">
                                       <img className="loglogoIcon" alt="" src="/logo@2x.png" />
                                  </Link>
              


                 <div className="logframeParent">

                 {activePage === 'login' ? (
                 <div className="logloginYourAccountParent">
                 <div className="logloginYourAccount1" onClick={() => handleButtonClick('login')} >Login your account</div>
                 <div className="loghaveAnAccountLogin1">
                 <div className="lognewToThirdretailContainer">
                 <span className='newtoretail'>New to ThirdRetail? </span>
                 <span
                         className="logcreateYourAccount"   style={{cursor: 'pointer' }} onClick={() => handleButtonClick('createAccount')}
                  >Create your Account</span>
                 </div>
                 </div>
                </div>
  ) : null}
  {activePage === 'createAccount' ? (
                <div className="logloginYourAccountParent">
                 <div className="logloginYourAccount1" >Create Your account</div>
                 <div className="loghaveAnAccountLogin1">
                 <div className="lognewToThirdretailContainer">
                 <span className='newtoretail'>Already have account?  </span>
                 <span
                         className="logcreateYourAccount"   style={{cursor: 'pointer'  }}  onClick={() => handleButtonClick('login')}
                  >Log in </span>
                 </div>
                 </div>
                </div>
) : null}


      <div className="logframeGroup">
       {activePage === 'login' ? (
        <div className='loginformauth'>

                <div className="loginput1">
                <div className='Authheadlinefield'>Email address</div>
                <div className="input-wrapper">
                <input type="text" 
                placeholder="Enter your email" 
                id = "loginput_email"
                value={email} onChange={(e) => setEmail(e.target.value)}  required />
                </div>
                </div>


             


                <div className="loginput2">
                <div className='Authheadlinefield'>Password</div>
                <div className="input-wrapper">
  <input
    type={showPassword ? 'text' : 'password'}
    placeholder="Password"
    id="loginput2_password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />
  <img
    className="eye-icon1"
    src={showPassword ? '/openeye.png' : '/closeeye.png'}
    alt={showPassword ? 'Open eye' : 'Closed eye'}
    onClick={handleTogglePassword}
  />
                 </div>
                </div>
               
                <div className ="log_in_forget_button"> 
                <div className ="signinbutton" onClick={handleSignIn}>Log in</div>
                <div className="logforgetYourPassword1">
                  Forget your password?
                </div>
                </div>
                 </div>
                 
      ) : null}
      {activePage === 'createAccount' ? (
                <div className='loginformauth_create_profile'>
                 
                 <div className="loginput1">
                 <div className='Authheadlinefield'>Email</div>
                 <div className="input-wrapper">
                 <input type="text" 
                  placeholder=" Enter your Email" 
                  id = "loginput_email"
                  value={email} onChange={(e) => setEmail(e.target.value)}  required />
                  </div>
                  </div>

               <div className='loginputname'>

              <div className="loginput09">
              <div className='Authheadlinefield'>last Name</div>
              <div className="input-wrapper">
                <input type="text" 
                placeholder="Enter your Last Name" 
                id = "create_account_lastname"
                value={lastName} onChange={(e) => setlastName(e.target.value)}  required />
                </div>
                </div>

                 <div className="loginput09">
                 <div className='Authheadlinefield'>First Name</div>
                 <div className="input-wrapper">
                <input type="text" 
                placeholder="Enter your First Name" 
                id = "create_account_firstname"
                value={firstName} onChange={(e) => setfirstName(e.target.value)}  required />
                </div>
                </div>
                </div>

                {/*}   defining role    {*/}
                <div className="loginput" >
      <div className='Authheadlinefield'>Role</div>
      <select
        value={role}
        id = "roleinput"
        onChange={handleRoleChange}
        required
        style={{
          padding: '12px',
      
          borderRadius: '8px',
          backgroundColor: '#ffffff',
          border: '1px solid #9aa8bc',
          width: '100%',
        }}
      >
        <option value="">Select Role</option>
        <option value="ADMIN">List Your Store</option>
        <option value="ECOMM">Rent A Shelf</option>
        </select>
        </div>

               
        <div className="loginput2"> 
                 <div className='Authheadlinefield'>Password</div> 
<div className="input-wrapper">
  <input
    type={showPassword ? 'text' : 'password'}
  placeholder="Enter your Password"
    id="loginput2_password"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
    required
  />
  <img
    className="eye-icon1"
    src={showPassword ? '/openeye.png' : '/closeeye.png'}
    alt={showPassword ? 'Open eye' : 'Closed eye'}
    onClick={handleTogglePassword}
  />
                 </div>
                </div>


                {password && !allValid && (
         <div className="password-requirements">
         <p style={{ color: isValidLength ? 'green' : '#728197', fontSize: "0.6rem", fontWeight:"500" }}>Minimum 7 characters long</p>
         <p style={{ color: hasUppercase ? 'green' : '#728197', fontSize: "0.6rem" , fontWeight:"500" }}>Contains at least 1 uppercase letter</p>
         <p style={{ color: hasLowercase ? 'green' : '#728197', fontSize: "0.6rem" , fontWeight:"500" }}>Contains at least 1 lowercase letter</p>
         <p style={{ color: hasNumber ? 'green' : '#728197', fontSize: "0.6rem" , fontWeight:"500"}}>Contains at least 1 number</p>
         <p style={{ color: hasSpecialChar ? 'green' : '#728197', fontSize: "0.6rem", fontWeight:"500" }}>Contains at least 1 special character (!@#$%^&*)</p>
       </div>
      )}


                <div className='checkboxandpolicy'>
                   <div className='agreementcheckbox'>
                 <input
          type="checkbox"
         
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange} required 
        /> 
        </div>
        <div className='agreementoncreateaccount'>
        By creating an account means you agree to the <Link  style={{color : "#09A7B3"}} to= "/Privacy" > Terms and Conditions</Link> , & <Link style={{color : "#09A7B3"}} to= "/Privacy" >our Privacy Policy</Link></div>
      </div>
           <div className='signupbutton_container'> <div className="signupbutton" onClick={CombinedClickHandler }  
       > Join ThirdRetail</div> </div>
        </div>
        
      ) : null}

<ToastContainer />
      </div>
      
                     </div>

                     </div>
     
                     </div>



 

<ToastContainer />
                     </div>


    </div>

  );
};

export default AuthPage ;
