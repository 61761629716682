import React from 'react'
import "./checkout.scss" 
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios';
import { useLocation   } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom'
import Mapcheckout from './Mapcheckout';
import { GoogleMap, LoadScript, Marker ,InfoWindow} from "@react-google-maps/api";



const CheckoutPage = () => {
const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const location = useLocation();
const { storeId } = useParams();
const searchParams = new URLSearchParams(location.search);


const [shelfIds, setShelfIds] = useState([]);



const selectedShelves = JSON.parse(
  decodeURIComponent(searchParams.get('selectedShelves'))
);
const totalRent1 = decodeURIComponent(searchParams.get('totalRent1'));
const totalRent = decodeURIComponent(searchParams.get('totalRent'));
const itemCount = selectedShelves.length;

//console.log("Number of items in selectedShelves:", itemCount);
	const [storeData, setStoreData] = useState(null);
  
	useEffect(() => {
	  if (storeId) {
		// Fetch all store records using the storeId
		const graphqlQuery = `
		  query {
			getAllStoreRecordsById(Id: "${storeId}") {
			  StoreName,
			  ShopCategory,
			  StoreAddress,
			  AverageOrderValue,
			  stroreImageUrl,
			  selectCity,
			  storeId,
			  PaymentReconciliation,
			  CustomerDemography,
			  SelectedState,
			  StoreType,
			  storeTiming,
			  shelfDetails {
			  shelfName,
			  shelf_id,
			  createdDate,
			  shelfImage_url,
			  shelfRent,
			  }
			}
		  }
		`;
  
		axios.post(graphqlEndpoint, { query: graphqlQuery })
		  .then(response => {
			
			//console.log('All Store Data:', response.data.data.getAllStoreRecordsById);
		
			setStoreData(response.data.data.getAllStoreRecordsById);
			if (response.data.data.getAllStoreRecordsById && response.data.data.getAllStoreRecordsById.shelfDetails) {
				response.data.data.getAllStoreRecordsById.shelfDetails.forEach(shelf => {
				//  console.log('Shelf ID: print new', shelf.shelf_id);
				  const fetchedShelfIds = response.data.data.getAllStoreRecordsById.shelfDetails.map(shelf => shelf.shelf_id);
				  setShelfIds(fetchedShelfIds);
		
				});
			  }
		  })
		
		  .catch(error => {
			console.error('Error fetching store data:', error);
		  });
	  }
	}, [storeId, graphqlEndpoint]);
	useEffect(() => {
		// Access shelfIds here or wherever needed
		
	  }, [shelfIds]);

	if (!storeData) {
		
		return <div>Loading...</div>;
	  }
	
	const addressMatch = storeData.SelectedState?.match(/"([^"]+)"\s*Latitude\s*:\s*([-+]?\d*\.\d+)\s*Longitude\s*:\s*([-+]?\d*\.\d+)/);
  
	 const StoreName = storeData.StoreName ;

	const address = addressMatch ? addressMatch[1].trim() : null;
	const latitude = addressMatch ? parseFloat(addressMatch[2]) : null;
	const longitude = addressMatch ? parseFloat(addressMatch[3]) : null;
	
	const containerStyle = {
		width: '100%',
		height: '400px' // Ensure height is specified
	  };
	  const defaultCenter = {
		lat: 37.7749,  // Default Latitude (San Francisco example)
		lng: -122.4194 // Default Longitude
	  };
	  const center = latitude && longitude ? { lat: latitude, lng: longitude } : defaultCenter;

	  const apiKey = 'AIzaSyByI1MXrBW50io37CBScK-6Qk4b8WksZZc'  ; // Replace with your actual API key
	 
  console.log("here" ,center)
  
    const storeimage =  storeData.stroreImageUrl ; 
	const imageUrls = storeimage ? storeimage.split(',') : [];
  
	// Use only the first image URL
	const mainImageUrl1 = imageUrls.length > 0 ? imageUrls[0] : null;
	//const mainImageUrl2 = imageUrls.length > 0 ? imageUrls[1] : null;

// unique id generation


// console.log('Shelf IDs:', shelfIds);

 //boooking handling 
 //console.log(totalRent);
 const handleBookingConfirmation = async () => {
    try {
    
      const response = await axios.post(graphqlEndpoint, {
        query: `
          mutation {
            sendBookingConfirmation(
				shelfId : "${shelfIds}" ,
                endDate: "${localStorage.getItem('selectedEndDate')}",
                startDate: "${localStorage.getItem('selectedStartDate')}",
                bookingId: "${sessionStorage.getItem('booking_id')}",
                email: "${sessionStorage.getItem('EcomUserEmail')}" ,
				amount : "${totalRent}" ,
            )
          }
        `,
      });

      // Handle the response as needed
   // console.log('Booking confirmation response:', response.data);
    } catch (error) {
      // Handle errors
      console.error('Error sending booking confirmation:', error);
    }
  };

 



  return (

    		<div className="checkoutbreadcrumbToDetachParent">

      			<div className="checkout_section_left">
    

               <div className="prev_page_links_checkout">

				   <Link  to="/" style={{ color: ' #09a7b3 ' }}>  <div className="checkoutlink">Home</div>
                   </Link>

				   <div className="prev_page_arrow" style={{color : "#09a7b3"}}> {`>`}</div>

                    <Link  to="/Browseshops" style={{ color: ' #09a7b3 ' }}> {storeData && (
                             <div className='checkoutlink'> <span  className='hide_on_mobile'>Listings in </span>   {storeData.selectCity}  </div>  )}
                    </Link>

						 <div className="prev_page_arrow3" style={{color : "#09a7b3"}}>{`>`}  </div>

        			<Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}> {storeData && (
                                     <div className='checkoutlink'>  {storeData.StoreName} </div> )}
                     </Link>

					 <div className="prev_page_arrow" style={{color : "#09a7b3"}}>{`>`}  </div>
				   
				   <div className="checkoutlink">Booking Confirmation</div>



				  </div>

				  <div className="checkout_booking_details_headline">
							   <Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}>
							     <img className="checkouticon7" alt="" src="/arrowleft.svg" />
                               </Link>
        				<div className="checkoutbookingConfirmation">Booking Confirmation</div>
      			</div>
			
            	 <div className="checkoutyourBooking_details">

              							<div className="checkoutyourBooking_headline">Your Booking</div>

              					                       
											<div className="checkout_date_edit" >

                  						            <div className="checkoutdateParent">
                    						        <div className="checkoutdate">Date</div>
                    								<div className="checkout_date_shelf_no">{localStorage.getItem('selectedStartDate')} - {localStorage.getItem('selectedEndDate')}</div>
                  									</div>
                  								
                    								<div className="checkouticon8">
													    <Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}>
														<img className="checkouticon9" alt="" src="/pencil.svg" />
                                                        </Link>
													</div>
            	                            </div>

											<div className="checkout_shelf_edit">  

												                    <div className="shelfand_shelf_no">
										                 <div className="checkoutdate">Shelfs</div>
                                                            <div className="countshlefincheckout">
                                                                <div className="checkout_date_shelf_no">{itemCount}</div>
                    										<div className="checkout_date_shelf_no"> Shelf</div>
															</div>
															</div>
															
															<Link  to={`/Browseshops/Sdp/${storeId}`} style={{ color: '#09a7b3' }}>
																  <img className="checkouticon9" alt="" src="/pencil.svg" />
                                                                          </Link>			

						 				     	</div>


            	 </div>
          				
			 <div className="checkoutabout_map_section">
          					
			 <div className="checkout_map_address">Address</div>

			 <div className="checkout_map"  >  
			 <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}  // Center the map at the provided latitude and longitude
        zoom={20}  // Zoom level of the map
      >
        {/* Add a marker at the provided latitude and longitude */}
        {latitude && longitude && (
          <Marker position={{ lat: latitude, lng: longitude }}>
            {/* Always display the custom InfoWindow */}
            <InfoWindow position={{ lat: latitude, lng: longitude }}>
              {/* Custom tooltip content with red color */}
              <div style={{ color: 'red', fontWeight: 'bold' }}>
                {address || "Location"}
              </div>
            </InfoWindow>
          </Marker>
        )}
      </GoogleMap>
    </LoadScript>
				</div>

				<div className="checkout_address_mestion">
						<img   className = "checkout_mappin" src ="/mappin.svg"  alt = ""  />
                		<div className="checkout_shelf_addresss" > {address}</div>
              	</div>

          	</div>

      			</div>




      		   	<div className="checkout_section_right">
        	
        				<div className="checkout_payment_container">

							
          					<div className="checkout_shelf_display">	
            					<img className="checkout_img_shelf" src = {mainImageUrl1} alt = "" />
          					</div>


          					<div className="checkout_payment_breakdown">
            						<div className="checkout_store_name">{storeData.StoreName}</div>
              						
                								<div className="checkoutbillingdetails_headline">Billing Details</div>
                							

											  <div className="checkout_billing_confirm_button">

											            <div className="billing_components_checkout">

                    									        <div className="checkout_details">
                      											<div className="checkout_breakdown_parameter"> Shelves Rent </div>
                      										   <div className="checkout_value">£{totalRent1}</div>
																 </div>
                    										   
																 <div className="checkout_details">
                      											<div className="checkout_breakdown_parameter">Booking Fee</div>
                      											<div className="checkout_value">£20/shelf</div>
                    										    </div>
															  

															   <div className="checkout_details">
                      											<div className="checkout_breakdown_parameter">VAT</div>
                      											<div className="checkout_value">20%</div>
                    										   </div>
                  									          
															   </div>


															   <div className="checkout_details">
                    									     	<div className="checkouttotal" style={{ fontWeight : "600"}}>Total</div>
                    										    <div className="checkoutdiv8" style={{ fontWeight : "600"}} >£{totalRent}</div>
																</div>

                							
                  								                       	<div className="checkoutlabel" onClick={handleBookingConfirmation}>									
												                  	  <Link  className="checkout_Link" to={`/confirmation/${storeId}?StoreName=${StoreName}&mainImageUrl1=${mainImageUrl1}&address=${address}&itemCount=${itemCount}`}>
                                                                      Confirm Booking
                                                                       </Link>
	                                                                      </div>	

																		  </div>
							   
							    </div>



							   </div>
							   </div>




							 


    		</div>
  )
  }
export default CheckoutPage
