import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import StoreBlock from './StoreBlock';
import Select from 'react-select';
import Mapp from './Map/Mapp';
import "./Browsestore.scss";
import User_form from './User_form/User_form';
//import { useSelector, useDispatch } from 'react-redux';
//import { showForm, hideForm } from '../../redux/formslice'

const Browsestore = () => {
  const graphqlEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT;
  const shelf_type = ['Rack', 'Standing'];
  const Types = ['Chain Store', 'Small Store', 'Brand Store', 'Supermarket', 'Multi Brand', 'Boutique Store', 'Others'];
  const Demographicss = ['Baby products', 'Minor', '18-24', '25-35', '45-65', '65+'];
  const Categorys = ['FMCG', 'Fashion', 'Electronics', 'Furniture & Interior', 'Kids', 'DIY & Harware', 'Gardening'];

  const [selectedshelf_type, setSelectedshelf_type] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedDemographic, setSelectedDemographic] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');

  const handleshelf_typeChange = (event) => {
    setSelectedshelf_type(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  const handleDemographicChange = (event) => {
    setSelectedDemographic(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const location = useLocation();

  const responseData = location.state?.responseData;

  const searchParams = new URLSearchParams(location.search);
  const formattedStartDate = searchParams.get("start");
  const formattedEndDate = searchParams.get("end");

  useEffect(() => {
   // console.log("Formatted Start Date in Browser Page:", formattedStartDate);
   // console.log("Formatted End Date in Browser Page:", formattedEndDate);
  }, [formattedStartDate, formattedEndDate]);

  const startDate = localStorage.getItem("selectedStartDate");
  const endDate = localStorage.getItem("selectedEndDate");

 // console.log("start-date from local storage", startDate)
 // console.log("end-date from local storage", endDate)

  const [storeData, setStoreData] = useState([]);

  useEffect(() => {
    let graphqlQuery;
  
    if (startDate && endDate) {
      // If both startDate and endDate are present
      graphqlQuery = `
        query {
          getRegisterStoresByAvailableDate(startDate: "${startDate}", endDate: "${endDate}") {
            storeId,
            StoreName,
            SelectedState,
            AverageOrderValue,
            stroreImageUrl,
            storeArea
            ShopCategory,
            CustomerDemography
            shelfDetails {
              shelfName,
              shelfRent,
              createdDate,
              shelf_id,
              shelfImage_url
            }
          }
        }
      `;
    } else {
      // If either startDate or endDate is null
      graphqlQuery = `
        query {
          getSynergyMarketList(averageOrderValue: "",
            location: "",
            customerDemographics: [""],
            prefferedProduct: [""]) {
            storeId,
            StoreName,
            storeArea
            SelectedState,
            AverageOrderValue,
            stroreImageUrl,
            ShopCategory,
            CustomerDemography
            shelfDetails {
              shelfName,
              shelfRent,
              createdDate,
              shelf_id,
              shelfImage_url
            }
          }
        }
      `;
    }
  
    axios.post(graphqlEndpoint, { query: graphqlQuery })
      .then(response => {
        if (startDate && endDate) {
          setStoreData(response.data.data.getRegisterStoresByAvailableDate || []);
        } else {
          setStoreData(response.data.data.getSynergyMarketList || []);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [graphqlEndpoint, startDate, endDate]);
  
  const extractLatLong = (selectedState) => {
    const match = selectedState.match(/Latitude : ([^ ]+) Longitude : ([^ ]+)/);
    if (match) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      return { latitude, longitude };
    }
    return null;
  };

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = storeData.slice(indexOfFirstItem, indexOfLastItem);

  const latLongArray = currentItems.map((item) => {
    const { SelectedState } = item;
    return extractLatLong(SelectedState);
  });

  const addressesOnCurrentPage = currentItems.map((item) => item.SelectedState);
  const storeNameOnCurrentPage = currentItems.map((item) => item.StoreName);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const nextPage = () => setCurrentPage((prev) => prev + 1);
  const prevPage = () => setCurrentPage((prev) => prev - 1);
 

 
   
   const [isUserFormVisible, setIsUserFormVisible] = useState(false);
  //    isUserFormVisible = useSelector(state => state.form.isUserFormVisible);
   //const dispatch = useDispatch();
   useEffect(() => {
     const selectedOptions = sessionStorage.getItem('selectedOptions');
     if (selectedOptions && selectedOptions !== '["FMCG"]') {
       setIsUserFormVisible(true);
     } else {
       setIsUserFormVisible(false);
     }
   }, []);
 
   const handleClearSessionStorage = () => {
     sessionStorage.clear();
     //console.log('Session storage cleared');
   };
 
   const handleClick = () => {
     setIsUserFormVisible((prevState) => {
       if (prevState) {
         setTimeout(() => {
           handleClearSessionStorage();
         }, 1000);
       }
       return !prevState;
     });
   };
   const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial load

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const currentDate = new Date();
  const formattedEndDate_1 = endDate || "DD/MM/YYYY";
  const formattedStartDate_2 = startDate || currentDate.toLocaleDateString();

  return (
    <div className='supermarketoptions'>
      <div className="supermarketoptionsdata">

        <div className="brwoseshoppagetext">
        <div className="superMarketsIn">Stores in </div>
        <span className="london">london </span>
        </div>
   

   <div className="result_date_wishlist">
   <div className="results">
        Results from {formattedStartDate_2} to {formattedEndDate_1}
       </div>
          <div   className="popup_wishliast_button">
          <div className='wishlist_button' onClick={handleClick}>
        {isUserFormVisible ? 'Close' : 'Request early access'}
      </div>
      </div>
   </div>
        


        <div className="options">

        <div className="option"    style={{ display: isMobile ? 'block' : 'none' }}>
            <select id="option" value={selectedshelf_type} onChange={handleshelf_typeChange}>
              <option value="">Shelf type</option>
              {shelf_type.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

        
          <div className="option">

            <select id="option" value={selectedType} onChange={handleTypeChange}>
              <option value="">Store type</option>
              {Types.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div> 
          
          <div className="option">
            <select id="option" value={selectedDemographic} onChange={handleDemographicChange}>
              <option value=""> Demography </option>
              {Demographicss.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="option">
            <select id="option" value={selectedCategory} onChange={handleCategoryChange}>
              <option value="">Shop category</option>
              {Categorys.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
       


        <div className='layerslp'>
          {currentItems.map((data, index) => (
            <StoreBlock key={index} {...data} />
          ))}
          <div className="pagination">
            <button className="prevButton" onClick={prevPage} disabled={currentPage === 1}>
              Prev
            </button>
            {Array.from({ length: Math.ceil(storeData.length / itemsPerPage) }).map((_, index) => (
              <span
                key={index}
                onClick={() => paginate(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </span>
            ))}
            <button className="nextButton" onClick={nextPage} disabled={currentPage === Math.ceil(storeData.length / itemsPerPage)}>
              Next
            </button>
          </div>
        </div>
      </div>
    
      <div className="smpgraph">
                        
        <div className="user_pop_wishlist"> {/*} <button onClick={() => dispatch(showForm())}>Show User Form</button>{*/}
        {isUserFormVisible && <User_form />}</div>
     
       <Mapp latLongArray={latLongArray} storeName={storeNameOnCurrentPage} />
      </div>
    </div>
  )
};

export default Browsestore;
