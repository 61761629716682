import React from 'react'
import "./confirmation.scss"
import { useEffect } from 'react';
import { Link ,useParams} from 'react-router-dom'
import { useLocation } from 'react-router-dom';
const Confirmation = () => {
	const location = useLocation();
    const { storeId } = useParams();

    // Extracting parameters from the URL
    const storeName = new URLSearchParams(location.search).get('StoreName');
	const address = new URLSearchParams(location.search).get('address');
    const imgUrl = new URLSearchParams(location.search).get('mainImageUrl1');
	
	const itemCount = new URLSearchParams(location.search).get('itemCount');


    
	 
    // console.log('selectState:', address);
	useEffect(() => {
		const disableBackButton = (event) => {

		  window.history.pushState(null, '', window.location.href);
		  window.onpopstate = function () {
			window.history.pushState(null, '', window.location.href);
		  };
		};
	
		disableBackButton();
	
		return () => {
		  window.onpopstate = null; // Clean up the event handler when component unmounts
		};
	  }, []);
	
  return (
    <div>

        <div className="confirmationgroupParent">
				
      		                <div className="confirmationframeWrapper">
                                <div className="confirmationmsg">
							        <img className="confirmationpositiveSolidIcon1" alt="" src="/progess_successfull.svg" />
            						<div className="confirmationbookingConfirmed">Booking requested</div>	
							    </div>		
                					<div className="confirmationitsOfficial">It’s official!</div>
                					<div className="confirmationitsOfficial">booking send for approval</div>
                                    <div className="confirmationyourReservationIs1">our team will reach out soon</div>
      			             </div>



      			             <div className="confirmation_details_Sections">


							            <img className="confirmationimageIcon" alt=""  src= {imgUrl}/>

                                                      
													  <div className="confirmation_details_information">
        				                            <div className="confirmationid">
                  									<div className="confirmationbookingId">Booking ID:</div>
                  									<div>{sessionStorage.getItem('booking_id') }</div>
													</div>
            					
								                    <div className="confirmation_Details_name_address">
                                                            <div className="confirmationbordeauxGetaway">{storeName}</div>
                							                <div className="address_mappin">
                  									                 <img className="confirmationmappinIcon" alt="" src="/mappin.svg" />
                  								                     <div className="confirmationbroadwayNewYork1">{address}</div>
															</div>
												    </div>
                					
            					
              							<div className="confirmationdates">Booked from  {"  "}{localStorage.getItem('selectedStartDate')}  to  {' '}
                                          {localStorage.getItem('selectedEndDate')}     ⦾{itemCount} Shelf booked</div>
            						    </div>
          					
										</div>
            					
          					
      			

                             <div className="confirmation_bottom_buttons">
							 <div className="confirmationbutton2">
          					<div className="confirmationlabel_2">My Bookings</div>
        				    </div>
				             <div className="confirmationbutton1">
          				       <Link style={{ textDecoration: 'none' }} to = "/"><div className="confirmationlabel_1"> Go to Home page</div></Link>
        			     	</div>
        			    
							</div>


    		</div>	
    </div>
  )
    }

export default Confirmation
